const preferences = {
  subscriptions: {
    title: "Subscriptions",
    newFeaturesTitle: "New features",
    newFeaturesContent: "Receive updates on the new features we've built.",
    newFeaturesAriaLabel: "Enable or disable new features subscription",
    wellnessTitle: "Wellness content",
    wellnessContent: "Receive infrequent news and research to help you meet your wellness goals.",
    wellnessAriaLabel: "Enable or disable wellness content subscription",
    promotionalTitle: "Promotional",
    promotionalContent: "Receive occasional emails that could help save you money on products.",
    promotionalAriaLabel: "Enable or disable promotional subscription",
  },
  unsubscribeWarningDialog: {
    reasonStayOnTrack:
      "Help you stay on track with your daily consumption by sending you an email before you run out, so you’ll never go empty.",
    reasonHelpYourPractitioner: "Help your practitioner to focus on your adherence.",
    reasonIsFullscriptWay: "Is Fullscript's way of helping you reach your health goals.",
    turnOffSubscriptions: "Turn off communications",
    keepSubscriptions: "No, I want to receive communications",
    beforeYouDo: "Before you do, consider that we:",
    unsubscribeWarning: "This will <1>turn off</1> all communications for this dispensary",
    dontWishToReceiveEmails:
      "I do <1>not</1> wish to receive any communication for products from this dispensary.",
  },
} as const;

export { preferences };
