/* eslint-disable @typescript-eslint/naming-convention */
const betaBadge = {
  beta: "Beta",
} as const;

const productImage = {
  altText: "Product image for {{name}}.",
  backordered: "Backordered",
  restricted: "Restricted",
  restrictedDescription:
    "This product cannot be purchased or sent via a plan because it is restricted.",
  backorderedWithNoReturnDate:
    "This item may take longer to ship. If it isn't available within 21 days after purchase, we'll cancel the order, issue a refund, and inform you by email.",
  backorderedWithReturnDate_plural:
    "Size backordered until approximately {{returnDate}} (in {{count}} days).",
  backorderedWithReturnDate:
    "Size backordered until approximately {{returnDate}} (in {{count}} day).",
  discontinued: "Discontinued",
  discontinuedNoReturnDate:
    "This product cannot be purchased or sent via a plan because it is discontinued.",
  discontinuedNoReturnDateForPatient:
    "This product cannot be purchased because it is discontinued.",
  toBeDiscontinued: "To be discontinued",
  toBeDiscontinuedNoReturnDate: "This product will be discontinued.",
  enlarge: "Click to enlarge product image.",
  gold: "Emerson Quality Program: Gold.",
  onlyOnFS: "Only on Fullscript",
  outOfStock: "Out of stock",
  outOfStockWithNoReturnDate: "This item is out of stock.",
  outOfStockWithReturnDate_plural:
    "Out of stock until approximately {{returnDate}} (in {{count}} days).",
  outOfStockWithReturnDate: "Out of stock until approximately {{returnDate}} (in {{count}} day).",
  quantity: "Quantity {{count}}.",
  silver: "Emerson Quality Program: Silver.",
} as const;

export { betaBadge, productImage };
