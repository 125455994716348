const twoFactor = {
  WhatIsTwoFactorExplanation:
    "Two-factor verification adds an extra layer of security to your " +
    "account. To log in, you'll need to provide a code along with your username " +
    "and password. This lets us know it's actually you.",
  PhoneNumber: "Phone Number",
  EnterMobileNumber: "Enter your mobile number below",
  EnsureCorrectMobileNumber: "Please ensure the mobile number entered is correct",
  EnableTwoFactor: "Enable Two-Factor Verification",
  UseTextMessage: "Use text message",
  UseApplication: "Use application",
  SendCode: "Send code",
} as const;

export { twoFactor };
