import merge from "@fastify/deepmerge";
import type { Resource } from "i18next";

import { sharedUS } from "@shared/locales";

const mergeSharedTranslations = <T extends Resource>(translations: T) => {
  return merge()(sharedUS, translations);
};

export { mergeSharedTranslations };
