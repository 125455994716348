const patientPromotions = {
  title: "{{Patient}} promotions",
  afterCutoff: {
    banner: {
      title: "A {{patientTerm}} promotion is about to start",
      message:
        "The participation window for this promotion has closed. You will be able to opt in to or opt out of upcoming promotions once the promotion has ended.",
    },
  },
  activePromotion: {
    banner: {
      title: "A {{patientTerm}} promotion is currently active",
      message: "It is not possible to update your participation in a promotion at this time.",
    },
  },
  willNotifyYouBeforeTitle: "We'll keep you informed",
  willNotifyYouBeforeTitleDescription:
    "Along with your practitioners and clerks, you’ll receive reminders leading up to promotions detailing when it starts, how long it will run for, and how much the discount is.",
  additionalPatientSavings: "Additional {{patientTerm}} savings",
  reachedMaxDiscount: "You are already offering the maximum discount available",
  total: "{{total}}% total savings for your {{patientTermPlural}}",
  description:
    "Patient promotions allow you to opt in to giving all of your {{patientTermPlural}} additional savings for a limited time period. For promotions you are opted in to, Fullscript will email all of your {{patientTermPlural}} letting them know that they have limited time additional savings on all products, which is automatically applied.",
  autoOptInError:
    "We couldn't opt you in to the \"{{promotionId}}\" promotion because it doesn't exist",
  autoOptInErrorGlobal: "We couldn't opt you in to all future promotions",
  autoOptInSuccess: "Successfully opted in to '{{promotionName}}' promotion",
  autoOptInSuccessAll: "Successfully opted in to all future promotions",
  optInCloseError: "Close error message",
  optInCloseSuccess: "Close success message",
  upcomingPromotion: "Upcoming promotion: ",
  currentPromotion: "Current promotion: ",
  optIn: "Opt in",
  optOut: "Opt out",
  optedIn: "Opted in",
  optedOut: "Opted out",
  optInAll: {
    title: "Opt me in to all <1>future promotions</1>",
  },
  optInDatesCommunication:
    "* The dates of the opt in period will be communicated to you in Fullscript emails leading up to the sale.",
  optInSpecific: {
    title: "Opt me in to a <1>specific promotion</1>",
    noSpecificPromotions: "There are currently no active or upcoming promotions",
  },
  alertBar: "Opt in to {{- name}} - Learn more here",
  promotionDates:
    "{{name}} will begin <strong>{{startDate}}</strong> and run until <strong>{{endDate}}</strong>.",
  timezoneInfo: "* All dates and times are displayed in your local timezone.",
  new: {
    descriptionLine1:
      "Motivate your {{patients}} to prioritize their health by offering them limited-time additional savings on supplements and personal care products. This is an easy way to boost adherence, keep {{patients}}  engaged, and drive orders.",
    descriptionLine2:
      "Simply opt in to all promotions, or select the event you want to participate in. We’ll take care of the rest!",
    optInHeader: "Are you ready to opt in?",
    optOutHeader: "Are you sure you would like to opt out?",
    total: "Total {{total}}%",
    optInDescription:
      "Please note that the promotional discount you choose will be added on top of your current dispensary discount and any additional discounts you give to individual {{patients}}, to a maximum of {{maxDiscount}}%",
    optInExample:
      "<0>Example:</0> if your current dispensary discount is 10% and you select a promotional discount of 10%, the total discount {{patients}} receive during the promotion will be 20%.",
    optOutDescription:
      "Opting out of promotions will stop your patients from recieving discounts on products. You can always opt back in at any time.",
    optOutCancelButton: "Stay opted in",
    optInCancelButton: "No, I'm not ready",
    optOutConfirmButton: "Yes, opt me out",
    optInConfirmButton: "Yes, I want to opt in",
  },
  FrequentlyAskedQuestions: "Frequently asked questions",
  FaqFooter: "Still uncertain about something? Get in touch with our <2>customer success team!</2>",
  faq: {
    promoDiscountsDispensary: {
      question: "Will promotional discounts be added to my dispensary discount?",
      answer:
        "Yes - any discount given <1>will</1> be in addition to your dispensary discount, up until the maximum discount you can offer {{patients}}. You can view your {{patients}}’ total savings by selecting different discount options above.",
    },
    promoDiscountsPatients: {
      question:
        "Will promotional discounts be added to existing, individual {{patient}} discounts?",
      answer:
        "Yes -  any discount given <1>will</1> be in addition to your individual {{patient}} discounts. This will affect your profit for the promotion period.",
    },
    howLong: {
      question: "How long do promotions run for?",
      answer:
        "Promotions vary in duration. We will specify the start and end time/date in your confirmation email, and in a reminder email leading up to the promotion.",
    },
    canOptOut: {
      question: "Can I opt out of promotions?",
      answer:
        "Yes! Even if you’ve previously opted in, you can always opt out of specific promotions up until the end of the opt-in period, which is communicated in your announcement and reminder emails from Fullscript.",
    },
    canOptIn: {
      question: "How long can I wait to opt in to promotions?",
      answer:
        "Similarly to opting out, you can opt in to a promotion until the end of the opt-in period for that sale. The date and time will be included in your announcement and reminder emails from Fullscript.",
    },
    canChangePromotionalDiscount: {
      question: "Can I change the promotional discount I’m offering while a promotion is running?",
      answer:
        "No. Promotional discounts <1>cannot</1> be changed within the duration of a promotion to which you have opted in.",
    },
    canChangeDispensaryDiscount: {
      question: "Can I change my dispensary discount while a promotion is running?",
      answer:
        "No. Dispensary discounts <1>cannot</1> be changed within the duration of a promotion to which you have opted in.",
    },
    discountValue: {
      question: "Where is the value of the promotional discount drawn from?",
      answer:
        "The value of the promotional discount is drawn from your margin. This discount is given in addition to your dispensary discount and any individual patient discounts.",
    },
    allPatients: {
      question: "Are all of my {{patients}} included in promotions?",
      answer: "All {{patients}} are included in promotions unless they:",
      answer1: "Are blocked from your store",
      answer2: "Have not set a password to activate their account",
    },
    notifyPatients: {
      question: "How will my {{patients}} be notified of the promotion?",
      answer:
        "We’ll send your {{patients}} emails notifying them of the sale on the days that the promotion is active. We’ll provide a sample of this email for you leading up to the promotion.",
    },
    socialMedia: {
      question: "Can I promote the sale to my {{patients}}?",
      answer:
        "Yes, although please read our <1>Acceptable Use Policy</1>  for guidelines around how to communicate the sale. We’ll provide all opted-in practitioners with a marketing toolkit that comes with resources and suggestions on how to promote the sale.",
    },
  },
  testimonials: {
    GeriBrester: {
      name: "Geri Brewster, RD MPH CDN",
      subTitle: "Fullscript user since 2015",
      quote:
        "Whatever magic you did in the background, I didn't have to get involved, and that was a beautiful thing.",
    },
    KrisSargent: {
      name: "Dr. Kris Sargent, DC",
      subTitle: "Fullscript user since 2016",
      quote:
        "It’s a great way to show people that you do care. I think running promotions is a win-win for the doctor and the patient.",
    },
  },
  acceptableUsePolicy: {
    title: "Acceptable use policy",
    description:
      "Be sure to review our acceptable use policy before marketing a promotion to your {{patients}}. We will contact any users who violate the <strong>Discounts and Promotions</strong> clause of the policy.",
    cta: "Read the policy",
  },
  letUsDoTheWork: {
    title: "Let us do the work!",
    optIn:
      "Once you opt in, Fullscript manages the rest of the promotion behind the scenes, including:",
    point1: "Setting up the sale in your dispensary, and automatically turning it on and off",
    point2: "Designing marketing materials and promoting the sale to your {{patients}}",
    point3:
      "Providing a marketing toolkit that you can use to drive awareness and get {{patients}} excited",
    point4: "Increasing resources to ship orders to your {{patients}}  fast",
    point5:
      "Dedicating Customer Success members to support both practitioners and {{patients}} during the promotion",
  },
  stats: {
    title:
      "On average, practitioners in {{countryTerm}} who participate in our {{patientTerm}} promos see:",
    point1: "A {{percent}}% increase in the number of ordering {{patientsTerm}}",
    point2: "A {{percent}}% increase in the number of first orders from new {{patientsTerm}}",
    point3:
      "A {{percent}}% increase in the number of new ordering {{patientsTerm}} who continue ordering after the sale",
    point4:
      "A {{percent}}% lift in the number of existing {{patientsTerm}} who place their first order of the year during a sale",
    point5: "A {{percent}}% increase in payouts",
    Disclaimer:
      "* Based on all historical {{patientTerm}} promos, for practitioners in {{countryTerm}}",
  },
} as const;

export { patientPromotions };
