const search = {
  SearchResultsRelatingTo: "Search results relating to:",
  Brand: "Brand",
  brand: "brand",
  Category: "Category",
  category: "category",
  Type: "Type",
  type: "type",
  Filter: "Filter",
  filter: "filter",
  Query: "Query",
  Error: "Error",
  RemovesColon: "Removes:",
  removeTagFromSearch: "Remove {{tagName}} from current search",
  ClearSearch: "Clear search",
  SearchForResultsRelatingTo: "Search results relating to:",
  inParent: "in {{parent}}",
  SearchBarPlaceholder: "Search for a product, brand, ingredient or condition",
  DidYouMeanTheType: "Did you mean the {{type}}",
} as const;

export { search };
